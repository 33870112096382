import { useCallback, useMemo, useEffect, useReducer, useRef } from 'react'

import { useGridApiContext } from '@mui/x-data-grid-premium'
import type { Schema } from '@rocicorp/zero/react'
import { useZero } from '@rocicorp/zero/react'
import _, { debounce } from 'lodash'
import type { UpdateViewInput } from 'types/graphql'

import { useQuery, useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { logger } from 'src/lib/logger'
import type { NativeObjectType } from 'src/lib/objects'

import { calculateOrderedPositions } from './lib'
import {
  CREATE_VIEW_MUTATION,
  DELETE_VIEW_MUTATION,
  SHARE_VIEW_MUTATION,
  UPDATE_VIEW_MUTATION,
  UPDATE_VIEW_PIN_MUTATION,
} from './mutations'
import { VIEWS_QUERY } from './queries'
import ViewsContext from './ViewsContext'

// Define action types as constants to avoid typos
const VIEW_ACTIONS = {
  SET_VIEWS: 'SET_VIEWS',
  ADD_VIEW: 'ADD_VIEW',
  SET_CURRENT_VIEW: 'SET_CURRENT_VIEW',
  REMOVE_VIEW: 'REMOVE_VIEW',
  UPDATE_VIEW: 'UPDATE_VIEW',
  TOGGLE_PIN: 'TOGGLE_PIN',
  SET_SAVING_STATUS: 'SET_SAVING_STATUS',
  APPLY_GRID_STATE: 'APPLY_GRID_STATE',
  OPTIMISTIC_UPDATE: 'OPTIMISTIC_UPDATE',
  REVERT_OPTIMISTIC: 'REVERT_OPTIMISTIC',
  SET_FILTER_COLUMN_REQUESTED: 'SET_FILTER_COLUMN_REQUESTED',
  UPDATE_PIN_POSITION: 'UPDATE_PIN_POSITION',
}

function viewsReducer(state, action) {
  switch (action.type) {
    case VIEW_ACTIONS.SET_VIEWS: {
      return {
        ...state,
        views: action.payload.views,
        pinnedViews: action.payload.pinnedViews,
        currentView: action.payload.currentView,
        error: null,
      }
    }

    case VIEW_ACTIONS.ADD_VIEW: {
      const newView = action.payload
      const newViews = [...state.views, newView]
      const pinnedViews = newViews
        .filter((view) => typeof view.position === 'number')
        .sort((a, b) => a.position - b.position)

      return {
        ...state,
        views: newViews,
        pinnedViews,
        currentView: newView,
        error: null,
      }
    }

    case VIEW_ACTIONS.UPDATE_VIEW: {
      const { id, changes } = action.payload
      const updatedViews = state.views.map((view) =>
        view.id === id ? { ...view, ...changes } : view
      )

      const pinnedViews = updatedViews
        .filter((view) => typeof view.position === 'number')
        .sort((a, b) => a.position - b.position)

      const updatedCurrentView =
        state.currentView?.id === id
          ? { ...state.currentView, ...changes }
          : state.currentView

      return {
        ...state,
        views: updatedViews,
        pinnedViews,
        currentView: updatedCurrentView,
        lastSyncedGridState:
          changes.gridState && id === state.currentView?.id
            ? changes.gridState
            : state.lastSyncedGridState,
      }
    }

    case VIEW_ACTIONS.SET_CURRENT_VIEW: {
      const newCurrentView = action.payload

      return {
        ...state,
        currentView: newCurrentView,
        lastSyncedGridState: newCurrentView?.gridState || null,
      }
    }

    case VIEW_ACTIONS.REMOVE_VIEW: {
      const viewId = action.payload
      const updatedViews = state.views.filter((view) => view.id !== viewId)
      const pinnedViews = updatedViews
        .filter((view) => typeof view.position === 'number')
        .sort((a, b) => a.position - b.position)

      // Determine new current view if we're removing the current one
      const currentViewChanged = state.currentView?.id === viewId
      let newCurrentView = state.currentView

      if (currentViewChanged) {
        const currentIndex = state.pinnedViews.findIndex((v) => v.id === viewId)
        newCurrentView =
          pinnedViews[currentIndex] ||
          pinnedViews[currentIndex - 1] ||
          pinnedViews[0] ||
          null
      }

      return {
        ...state,
        views: updatedViews,
        pinnedViews,
        currentView: newCurrentView,
        lastSyncedGridState: newCurrentView?.gridState || null,
      }
    }

    case VIEW_ACTIONS.TOGGLE_PIN: {
      const { id, pinned, position } = action.payload

      const updatedViews = state.views.map((view) => {
        if (view.id !== id) return view

        return {
          ...view,
          position: pinned ? position : undefined,
        }
      })

      const pinnedViews = updatedViews
        .filter((view) => typeof view.position === 'number')
        .sort((a, b) => a.position - b.position)

      return {
        ...state,
        views: updatedViews,
        pinnedViews,
      }
    }

    case VIEW_ACTIONS.UPDATE_PIN_POSITION: {
      const { id, position } = action.payload

      // Use the utility function to calculate the correct positions
      const updatedViews = calculateOrderedPositions(state.views, id, position)

      // Calculate pinnedViews from the updated views
      const pinnedViews = updatedViews
        .filter((view) => typeof view.position === 'number')
        .sort((a, b) => a.position - b.position)

      return {
        ...state,
        views: updatedViews,
        pinnedViews,
      }
    }

    case VIEW_ACTIONS.SET_SAVING_STATUS: {
      return {
        ...state,
        saving: action.payload,
      }
    }

    case VIEW_ACTIONS.APPLY_GRID_STATE: {
      const gridState = action.payload

      // Only update if we have a current view
      if (!state.currentView) return state

      const updatedCurrentView = {
        ...state.currentView,
        gridState,
      }

      // Update the view in the array as well
      const updatedViews = state.views.map((view) =>
        view.id === updatedCurrentView.id ? updatedCurrentView : view
      )

      return {
        ...state,
        views: updatedViews,
        currentView: updatedCurrentView,
        lastSyncedGridState: gridState,
      }
    }

    case VIEW_ACTIONS.OPTIMISTIC_UPDATE: {
      const { operationId, changes } = action.payload

      return {
        ...state,
        pendingOperations: {
          ...state.pendingOperations,
          [operationId]: {
            previousState: _.cloneDeep(state),
            changes,
          },
        },
      }
    }

    case VIEW_ACTIONS.REVERT_OPTIMISTIC: {
      const { operationId } = action.payload
      const pendingOp = state.pendingOperations[operationId]

      if (!pendingOp) return state

      // Remove this operation from pending list
      const { [operationId]: _, ...remainingOperations } =
        state.pendingOperations

      // Revert to previous state
      return {
        ...pendingOp.previousState,
        pendingOperations: remainingOperations,
        error: action.payload.error || 'Operation failed',
      }
    }

    case VIEW_ACTIONS.SET_FILTER_COLUMN_REQUESTED: {
      return {
        ...state,
        filterColumnRequested: action.payload,
      }
    }

    default:
      return state
  }
}

const ViewsProvider = ({
  children,
  objectType,
  workspaceId,
  lastGridChangeAt,
  setInitialized,
  onSave,
  expandedGroupsRef,
  handleGroupExpansionChange,
  filterColumnRequested,
  resetFilterColumnRequested,
  initialState,
}) => {
  const _z = useZero<Schema>()
  const { currentUser } = useAuth()
  const apiGridRef = useGridApiContext()

  const [state, dispatch] = useReducer(viewsReducer, {
    ...initialState,
    objectType,
  })
  const {
    data: viewsData,
    loading,
    error,
    refetch,
  } = useQuery(VIEWS_QUERY, {
    variables: { objectType, workspaceId },
    skip: !objectType || !workspaceId,
  })

  const initializedFromDataRef = useRef(false)
  const optimisticUpdateCounterRef = useRef(0)

  // Grid state management
  const getExternalGridState = useCallback(() => {
    const gridState = apiGridRef.current?.exportState()
    // Remove preference panel from the state
    if (gridState && gridState.preferencePanel) {
      delete gridState.preferencePanel
    }
    return gridState
  }, [apiGridRef])

  const setExternalGridState = useCallback(
    (gridState) => {
      logger.dev('Calling setExternalGridState/restoreState with:', {
        gridState,
        apiRefAvailable: !!apiGridRef.current,
      })
      if (!gridState || !apiGridRef.current) return
      apiGridRef.current.restoreState(gridState)
    },
    [apiGridRef]
  )

  // Set current view and restore its grid state
  const activateView = useCallback(
    (view) => {
      if (!view) return
      logger.dev('Activating view and setting/resetting grid state', {
        viewId: view.id,
        hasGridState: !!view.gridState,
      })

      dispatch({
        type: VIEW_ACTIONS.SET_CURRENT_VIEW,
        payload: view,
      })

      // Check if gridState exists AND has keys (is not just an empty object {})
      if (view.gridState && Object.keys(view.gridState).length > 0) {
        // Validate filters
        const filters = view.gridState.filter?.filterModel?.items
        if (Array.isArray(filters)) {
          const validFilters = filters.filter((filter) => {
            const columnDefinitionOperators = apiGridRef.current?.getColumn(
              filter.field
            )?.filterOperators

            logger.dev('columnDefinitionOperators', {
              columnDefinitionOperators,
              filter,
            })

            return (
              ![null, undefined].includes(filter.field) &&
              columnDefinitionOperators?.includes(filter.operator)
            )
          })
          if (validFilters.length !== filters.length) {
            logger.warn('Invalid filters found in view', {
              viewId: view.id,
              invalidFilters: filters.filter(
                (filter) => !validFilters.includes(filter)
              ),
            })
          }
          const safeGridState = {
            ...view.gridState,
            filter: {
              ...view.gridState.filter,
              filterModel: { items: validFilters },
            },
          }
          logger.dev('Setting external grid state from view', {
            viewId: view.id,
          })
          setExternalGridState(safeGridState)
        }
      } else {
        // Explicitly reset grid state using the provided gridInitialState
        logger.dev('Resetting external grid state using gridInitialState', {
          viewId: view.id,
        })
        setExternalGridState(initialState)
      }
    },
    [setExternalGridState, initialState, apiGridRef]
  )

  // Initialize with fetched data
  useEffect(() => {
    if (!initializedFromDataRef.current && viewsData?.views) {
      const allViews = viewsData.views
      const pinnedViews = allViews
        .filter((view) => typeof view.position === 'number')
        .sort((a, b) => a.position - b.position)

      let initialCurrentView = null
      if (viewsData.currentViewId) {
        const viewFromId = allViews.find(
          (v) => v.id === viewsData.currentViewId
        )
        // Check if the view found by ID is actually pinned
        if (viewFromId && pinnedViews.some((pv) => pv.id === viewFromId.id)) {
          initialCurrentView = viewFromId
          logger.dev(
            'Initial view: Found stored currentViewId and it is pinned',
            { viewId: initialCurrentView.id }
          )
        }
      }

      // If no valid stored+pinned view, try the first pinned view
      if (!initialCurrentView && pinnedViews.length > 0) {
        initialCurrentView = pinnedViews[0]
        logger.dev('Initial view: Using first pinned view', {
          viewId: initialCurrentView.id,
        })
      }

      // If still no view (no pinned views), initialCurrentView remains null
      if (!initialCurrentView) {
        logger.dev(
          'Initial view: No pinned views found, starting with null current view'
        )
      }

      const payload = {
        views: allViews,
        pinnedViews: pinnedViews,
        currentView: initialCurrentView, // Use the determined initial view
      }

      dispatch({
        type: VIEW_ACTIONS.SET_VIEWS,
        payload,
      })

      // Activate the determined initial view (might be null)
      activateView(initialCurrentView)

      initializedFromDataRef.current = true
      setInitialized()
    }
  }, [viewsData, setInitialized, activateView])

  // GraphQL mutations
  const [createViewMutation, { loading: createViewLoading }] =
    useMutation(CREATE_VIEW_MUTATION)
  const [updateViewMutation, { loading: updateViewLoading }] =
    useMutation(UPDATE_VIEW_MUTATION)
  const [deleteViewMutation, { loading: deleteViewLoading }] =
    useMutation(DELETE_VIEW_MUTATION)
  const [shareViewMutation, { loading: shareViewLoading }] =
    useMutation(SHARE_VIEW_MUTATION)
  const [updateViewPinMutation, { loading: updateViewPinLoading }] =
    useMutation(UPDATE_VIEW_PIN_MUTATION)

  // Track saving status
  useEffect(() => {
    const isSaving =
      updateViewLoading ||
      createViewLoading ||
      deleteViewLoading ||
      shareViewLoading ||
      updateViewPinLoading

    dispatch({
      type: VIEW_ACTIONS.SET_SAVING_STATUS,
      payload: isSaving,
    })
  }, [
    updateViewLoading,
    createViewLoading,
    deleteViewLoading,
    shareViewLoading,
    updateViewPinLoading,
  ])

  // Helper for generating operation IDs
  const getNextOperationId = useCallback(() => {
    optimisticUpdateCounterRef.current += 1
    return `op-${Date.now()}-${optimisticUpdateCounterRef.current}`
  }, [])

  // Create a new view
  const createView = useCallback(
    async (input: any, options?: { useCurrentState?: boolean }) => {
      const operationId = getNextOperationId()
      try {
        // Prepare the view with current or empty grid state based on options
        const gridState = options?.useCurrentState
          ? getExternalGridState()
          : initialState

        const newView = {
          ...input,
          id: `temp-${operationId}`,
          gridState,
          workspaceId,
          position: input.position ?? state.pinnedViews.length,
        }

        // Optimistically update state
        dispatch({
          type: VIEW_ACTIONS.OPTIMISTIC_UPDATE,
          payload: {
            operationId,
            changes: { newView },
          },
        })

        dispatch({
          type: VIEW_ACTIONS.ADD_VIEW,
          payload: newView,
        })

        // Activate the newly created view to apply/reset grid state
        logger.dev('Activating newly created view', { viewId: newView.id })
        activateView(newView)

        // Execute mutation

        const mutationInput = {
          ...input,
          gridState,
          workspaceId,
          objectType,
          position: (state.pinnedViews.length ?? 0) + 1,
        }

        logger.dev('Creating view', mutationInput)

        const response = await createViewMutation({
          variables: {
            input: mutationInput,
          },
        })

        if (response.data?.createView) {
          // Replace temp ID with real one
          dispatch({
            type: VIEW_ACTIONS.UPDATE_VIEW,
            payload: {
              id: newView.id,
              changes: response.data.createView,
            },
          })

          onSave()
          return response.data.createView
        }

        return null
      } catch (error) {
        logger.error('Error creating view:', error)

        // Revert optimistic update
        dispatch({
          type: VIEW_ACTIONS.REVERT_OPTIMISTIC,
          payload: { operationId, error },
        })

        throw error
      }
    },
    [
      createViewMutation,
      workspaceId,
      getExternalGridState,
      state.pinnedViews?.length,
      getNextOperationId,
      onSave,
      objectType,
      activateView,
      initialState,
    ]
  )

  // Update an existing view
  const updateView = useCallback(
    async ({ id, input }: { id: string; input: UpdateViewInput }) => {
      const view = state.views.find((v) => v.id === id)
      logger.dev('updateView', {
        id,
        input,
        view,
        currentUser,
        authorized: currentUser?.id === view?.creatorId,
      })
      if (currentUser?.id !== view?.creatorId) {
        logger.dev(
          `Unauthorized attempt to update view ${id} by user ${currentUser?.id} who is not the creator`
        )
        return null
      }

      const operationId = getNextOperationId()

      try {
        // For optimistic updates, we need the updated grid state
        const gridState = input.gridState || getExternalGridState()
        input.workspaceId = workspaceId

        // Track previous state before optimistic update
        dispatch({
          type: VIEW_ACTIONS.OPTIMISTIC_UPDATE,
          payload: {
            operationId,
            changes: { updatedView: { id, ...input } },
          },
        })

        // Apply optimistic update
        dispatch({
          type: VIEW_ACTIONS.UPDATE_VIEW,
          payload: {
            id,
            changes: { ...input, gridState },
          },
        })

        // Execute actual mutation
        const response = await updateViewMutation({
          variables: {
            id,
            input: {
              ...input,
              gridState,
            },
          },
        })
        logger.dev('updateView response', response)

        onSave()
        return response.data?.updateView
      } catch (error) {
        logger.error('Error updating view:', error)

        // Revert optimistic update
        dispatch({
          type: VIEW_ACTIONS.REVERT_OPTIMISTIC,
          payload: { operationId, error },
        })

        throw error
      }
    },
    [
      updateViewMutation,
      workspaceId,
      getExternalGridState,
      getNextOperationId,
      onSave,
      state.views,
    ]
  )

  // Delete a view
  const deleteView = useCallback(
    async (viewId) => {
      if (!viewId) return
      const operationId = getNextOperationId()

      try {
        // Track state before deletion for potential rollback
        dispatch({
          type: VIEW_ACTIONS.OPTIMISTIC_UPDATE,
          payload: {
            operationId,
            changes: { deletedViewId: viewId },
          },
        })

        // Optimistically remove view
        dispatch({
          type: VIEW_ACTIONS.REMOVE_VIEW,
          payload: viewId,
        })

        // Execute actual deletion
        await deleteViewMutation({
          variables: { id: viewId, workspaceId },
        })

        return true
      } catch (error) {
        logger.error('Error deleting view:', error)

        // Revert optimistic update
        dispatch({
          type: VIEW_ACTIONS.REVERT_OPTIMISTIC,
          payload: { operationId, error },
        })

        throw error
      }
    },
    [deleteViewMutation, workspaceId, getNextOperationId]
  )

  // Handle pin/unpin
  const togglePin = useCallback(
    async (id, shouldPin) => {
      const operationId = getNextOperationId()

      try {
        const position = shouldPin ? state.pinnedViews.length : undefined

        // Track state before change
        dispatch({
          type: VIEW_ACTIONS.OPTIMISTIC_UPDATE,
          payload: {
            operationId,
            changes: {
              viewId: id,
              pinChange: {
                pinned: shouldPin,
                position,
              },
            },
          },
        })

        // Optimistically update pin status
        dispatch({
          type: VIEW_ACTIONS.TOGGLE_PIN,
          payload: {
            id,
            pinned: shouldPin,
            position,
          },
        })

        // If pinning, also make it the current view and activate its grid state
        if (shouldPin) {
          const viewToPin = state.views.find((v) => v.id === id)
          if (viewToPin) {
            // Directly activate the view instead of just dispatching SET_CURRENT_VIEW
            logger.dev('Activating newly pinned view', { viewId: viewToPin.id })
            activateView({ ...viewToPin, position })
          }
        }

        // Execute actual mutation
        const response = await updateViewPinMutation({
          variables: {
            id,
            workspaceId,
            position,
            objectType,
          },
        })

        return response.data?.updateViewPin
      } catch (error) {
        logger.error('Error toggling pin:', error)

        // Revert optimistic update
        dispatch({
          type: VIEW_ACTIONS.REVERT_OPTIMISTIC,
          payload: { operationId, error },
        })

        throw error
      }
    },
    [
      updateViewPinMutation,
      state.views,
      state.pinnedViews,
      objectType,
      workspaceId,
      getNextOperationId,
      activateView,
    ]
  )

  const handleMovePin = useCallback(
    async (id, position) => {
      const operationId = getNextOperationId()

      try {
        // Track state before change
        dispatch({
          type: VIEW_ACTIONS.OPTIMISTIC_UPDATE,
          payload: {
            operationId,
            changes: {
              viewId: id,
              pinChange: {
                position,
              },
            },
          },
        })

        // Optimistically update pin position
        dispatch({
          type: VIEW_ACTIONS.UPDATE_PIN_POSITION,
          payload: {
            id,
            position,
          },
        })

        // Execute actual mutation
        const response = await updateViewPinMutation({
          variables: {
            id,
            workspaceId,
            position,
            objectType,
          },
        })

        return response.data?.updateViewPin
      } catch (error) {
        logger.error('Error moving pin:', error)

        // Revert optimistic update
        dispatch({
          type: VIEW_ACTIONS.REVERT_OPTIMISTIC,
          payload: { operationId, error },
        })

        throw error
      }
    },
    [updateViewPinMutation, workspaceId, objectType, getNextOperationId]
  )

  useEffect(() => {
    if (filterColumnRequested) {
      logger.dev('Setting filter column requested', filterColumnRequested)
      dispatch({
        type: VIEW_ACTIONS.SET_FILTER_COLUMN_REQUESTED,
        payload: filterColumnRequested,
      })
    }
  }, [filterColumnRequested])

  // Convenience wrappers
  const addPin = useCallback((id) => togglePin(id, true), [togglePin])

  const removePin = useCallback((id) => togglePin(id, false), [togglePin])

  // Share a view with another user
  const shareView = useCallback(
    async (id, userId) => {
      try {
        const response = await shareViewMutation({
          variables: { id, userId },
        })
        return response.data?.shareView
      } catch (error) {
        logger.error('Error sharing view:', error)
        throw error
      }
    },
    [shareViewMutation]
  )

  const lastGridSavedAt = useRef(0)
  // Debounced handler for auto-saving grid state changes
  const debouncedHandleExternalGridChange = useMemo(
    () =>
      debounce(async () => {
        const saveUnnecessary =
          lastGridChangeAt === 0 ||
          !state.currentView?.id ||
          lastGridSavedAt.current > lastGridChangeAt

        logger.dev('[Debounced Save] Check', {
          lastGridChangeAt,
          currentViewId: state.currentView?.id,
          lastGridSavedAt: lastGridSavedAt.current,
          saveUnnecessary,
          hasCurrentView: !!state.currentView,
        })
        if (saveUnnecessary) {
          return
        }

        const currentGridState = getExternalGridState()

        const isEqual = _.isEqual(state.lastSyncedGridState, currentGridState)
        logger.dev('[Debounced Save] State Comparison', {
          isEqual,
          lastSynced: state.lastSyncedGridState,
          currentCaptured: currentGridState,
        })

        if (isEqual) {
          return
        }

        logger.dev('[Debounced Save] Proceeding to update view', {
          viewId: state.currentView.id,
        })
        try {
          await updateView({
            id: state.currentView.id,
            input: {
              gridState: currentGridState as any,
              title: state.currentView.title,
              description: state.currentView.description,
              shareWithWorkspace: state.currentView.shareWithWorkspace,
              workspaceId,
            },
          })
          lastGridSavedAt.current = Date.now()
        } catch (error) {
          logger.error('[Debounced Save] Error auto-saving grid state:', error)
          lastGridSavedAt.current = Date.now()
        }
      }, 500),
    [
      updateView,
      getExternalGridState,
      lastGridChangeAt,
      state.currentView,
      state.lastSyncedGridState,
      workspaceId,
    ]
  )

  // Trigger auto-save when grid state changes
  useEffect(() => {
    if (state.currentView?.id) {
      debouncedHandleExternalGridChange()
    }

    return () => {
      debouncedHandleExternalGridChange.cancel()
    }
  }, [
    lastGridChangeAt,
    debouncedHandleExternalGridChange,
    state.currentView?.id,
  ])

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => {
    return {
      views: state.views,
      pinnedViews: state.pinnedViews,
      currentView: state.currentView,
      loading,
      saving: state.saving,
      error: error || state.error,
      objectType: (state.objectType || objectType) as NativeObjectType,
      createView,
      updateView,
      deleteView,
      shareView,
      addPin,
      removePin,
      movePin: handleMovePin,
      activateView,
      refetchViews: refetch,
      getExternalGridState,
      dispatch,
      expandedGroupsRef,
      handleGroupExpansionChange,
      filterColumnRequested,
      resetFilterColumnRequested,
    }
  }, [
    state.views,
    state.pinnedViews,
    state.currentView,
    loading,
    state.saving,
    error,
    objectType,
    createView,
    updateView,
    deleteView,
    shareView,
    addPin,
    removePin,
    handleMovePin,
    activateView,
    refetch,
    dispatch,
    expandedGroupsRef,
    handleGroupExpansionChange,
    getExternalGridState,
    state.objectType,
    state.error,
    filterColumnRequested,
    resetFilterColumnRequested,
  ])

  return (
    <ViewsContext.Provider value={contextValue}>
      {children}
    </ViewsContext.Provider>
  )
}

export default ViewsProvider
