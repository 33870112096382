import { useContext, useEffect, useMemo, useState } from 'react'

import {
  Box,
  GlobalStyles,
  List,
  ListItemButton,
  Typography,
  Popper,
  IconButton,
  Paper,
  Divider,
  ListItemIcon,
  Tooltip,
} from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { Zero, type Schema } from '@rocicorp/zero'
import { ZeroProvider } from '@rocicorp/zero/react'
import {
  IconBolt,
  IconBubbleText,
  IconBuildingSkyscraper,
  IconBuildingStore,
  IconCalendar,
  IconChartBar,
  IconChartScatter,
  IconCoins,
  IconColumns2,
  IconFileImport,
  IconFiles,
  IconHeartHandshake,
  IconInbox,
  IconLaurelWreath,
  IconMessageCheck,
  IconMicrophone,
  IconReportAnalytics,
  IconSearch,
  IconServer,
  IconSitemap,
  IconStack2,
  IconTrophy,
  IconUser,
} from '@tabler/icons-react'
import ReactGA from 'react-ga4'
import { Toaster } from 'react-hot-toast'
import { useHotkeys } from 'react-hotkeys-hook'
import { v4 as uuid } from 'uuid'

import { routes, Link } from '@redwoodjs/router'
import { useLocation } from '@redwoodjs/router'
import { useRouteName } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import { AvatarCacheProvider } from 'src/components/AvatarCacheProvider/AvatarCacheProvider'
import { ObjectCacheProvider } from 'src/components/ObjectCacheProvider/ObjectCacheProvider'
import SidebarProvider from 'src/components/Objects/ObjectSidebar/SidebarProvider'
import ObjectsProvider from 'src/components/Objects/ObjectsProvider'
import SearchModal from 'src/components/Search/SearchModal/SearchModal'
import Sidebar from 'src/components/Sidebar/Sidebar'
import UserErrorRemediation from 'src/components/UserErrorRemediation/UserErrorRemediation'
import WorkspaceInviteBanner from 'src/components/WorkspaceInviteBanner/WorkspaceInviteBanner'
import MeetingRecordingsProvider from 'src/hooks/useMeetingRecordings/MeetingRecordingsProvider'
import ProfileController from 'src/layouts/ModernLayout/ProfileController/ProfileController'
import WorkspaceDropdown from 'src/layouts/ModernLayout/WorkspaceDropdown/WorkspaceDropdown'
import { DayContext, DayProvider } from 'src/lib/dayContext'
import {
  ungatedForActivityReporting,
  ungatedForCustomers,
  ungatedForDayObjectsPart3,
  ungatedForInbox,
  ungatedForMarketing,
  ungatedForProduct,
  ungatedForTemplates,
} from 'src/lib/gates'
import { logger } from 'src/lib/logger'
import { schema } from 'src/zero/schema'

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID, {
  nonce: uuid(),
})

type ModernLayoutProps = {
  children?: React.ReactNode
}

interface NavItemProps {
  icon: React.ReactElement
  label: string
  route: string
  subItems?: Array<{
    label: string
    route: string
    icon: React.ReactElement
  }>
}

// Style constants
const navItemButtonSx = {
  height: '40px',
  width: '48px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
  my: 0.5,
  mx: 'auto',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: (theme) => theme.palette.secondary.light,
  },
  '& .tabler-icon': {
    flexShrink: 0,
  },
}

const navItemButtonActiveSx = {
  ...navItemButtonSx,
  backgroundColor: (theme) => theme.palette.secondary.light,
}

const navPopoverPaperSx = {
  width: 220,
  marginLeft: 0,
  overflow: 'hidden',
  boxShadow: (theme) => theme.shadows[1],
  backgroundColor: (theme) => theme.palette.background.default,
  borderRadius: 1,
  zIndex: 1300,
  border: (theme) => `1px solid ${theme.palette.divider}`,
  '& .nav-item-title': {
    fontSize: '13px',
    fontWeight: 600,
    color: (theme) => theme.palette.text.primary,
    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
    backgroundColor: (theme) => theme.palette.background.paper,
    letterSpacing: '-0.32px',
    px: '12px',
    pb: 0,
    py: '6px',
  },
}

const navPopoverListSx = {
  p: 1,
  pt: '10px',
  gap: '4px',
  '& .MuiListItemButton-root': {
    backgroundColor: (theme) => `${theme.palette.background.paper} !important`,
    mb: '6px',
    boxShadow: (theme) => theme.shadows[1],

    '&:hover': {
      backgroundColor: (theme) => theme.palette.secondary.light,
    },
  },
}

const navPopoverItemBaseSx = {
  height: '32px',
  borderRadius: '4px',
  px: '2px',
  '& .MuiListItemIcon-root': {
    minWidth: '16px',
    marginRight: '6px',
    ml: '2px',
    mt: '1px',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

const navPopoverItemTextSx = {
  fontSize: '12px',
  fontWeight: 500,
  color: (theme) => theme.palette.text.primary,
}

const createZeroInstance = (
  userId: string | undefined,
  getToken: () => Promise<string | null>
) =>
  new Zero({
    userID: userId ?? 'anonymous',
    auth: userId ? () => getToken() : undefined,
    server:
      process.env.NODE_ENV === 'production'
        ? 'https://sync.prod.day.ai'
        : 'https://sync.dev.day.ai',
    schema,
    kvStore: 'idb',
    logLevel: 'debug',
    hiddenTabDisconnectDelay: 60_000,
  })

const NavItem = React.memo(
  ({ icon, label, route, subItems = [] }: NavItemProps) => {
    const location = useLocation()
    const isCurrentRoute = location.pathname === route
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }

    const handleMouseLeave = () => {
      setAnchorEl(null)
    }

    const isActive = isCurrentRoute || Boolean(anchorEl)

    return (
      <Box onMouseLeave={handleMouseLeave}>
        <ListItemButton
          component={Link}
          to={route}
          sx={{
            ...(isActive ? navItemButtonActiveSx : navItemButtonSx),
            position: 'relative',
          }}
          onMouseEnter={handleMouseEnter}
        >
          {React.cloneElement(icon, {
            size: 22,
            stroke: 2,
          })}
        </ListItemButton>

        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="right-start"
          sx={{ zIndex: 1300 }}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 0],
              },
            },
          ]}
        >
          <Paper
            sx={navPopoverPaperSx}
            onMouseEnter={() => setAnchorEl(anchorEl)}
          >
            <Typography
              className="nav-item-title"
              sx={navPopoverItemTextSx}
            >
              {label}
            </Typography>
            <List sx={navPopoverListSx}>
              {subItems.map((item) => {
                const _isSelected = location.pathname === item.route

                return (
                  <ListItemButton
                    key={item.route}
                    component={Link}
                    to={item.route}
                    sx={{
                      ...navPopoverItemBaseSx,
                      background: (theme) => theme.palette.secondary.light,
                    }}
                  >
                    <ListItemIcon>
                      {React.cloneElement(item.icon, {
                        size: 16,
                        stroke: 2,
                      })}
                    </ListItemIcon>
                    <Typography sx={navPopoverItemTextSx}>
                      {item.label}
                    </Typography>
                  </ListItemButton>
                )
              })}
            </List>
          </Paper>
        </Popper>
      </Box>
    )
  }
)

NavItem.displayName = 'NavItem'

const ModernLayoutInner = ({ children }: ModernLayoutProps) => {
  const { currentUser, getToken } = useAuth()
  const [searchOpen, setSearchOpen] = useState(false)

  // Bind to command/ctrl + k
  useHotkeys(
    'mod+k',
    (event) => {
      event.preventDefault()
      setSearchOpen(true)
    },
    { enableOnFormTags: true }
  )

  const {
    userCoreContact,
    sidebarObject,
    setSidebarObject,
    selectedWorkspace,
    workspaces,
  } = useContext(DayContext)

  const workspaceInvites = workspaces?.filter(
    (workspace) => workspace.status === 'INVITED'
  )

  const globalStyles = useMemo(() => {
    return {
      body: {
        overscrollBehaviorX: 'none',
        backgroundColor: (theme) =>
          `${theme.palette.background.paper} !important`,
      },
    }
  }, [])

  const [z, setZero] = useState<Zero<Schema>>(() =>
    createZeroInstance(currentUser?.id, getToken)
  )

  useEffect(() => {
    if ((currentUser?.id ?? 'anonymous') !== z.userID) {
      setZero(createZeroInstance(currentUser?.id, getToken))
    }

    return () => {
      z.close()
    }
  }, [currentUser?.id, getToken, z])

  const containerSx = useMemo(() => {
    return {
      color: (theme) => theme.palette.text.primary,
      background: (theme) => theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'row',
      height: '100vh',
      overflow: 'hidden',
      '& .sidebar': {
        color: (theme) => theme.palette.text.primary,
        background: (theme) => theme.palette.background.paper,
        width: '48px',
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        zIndex: 3,
        display: 'flex',
        flexDirection: 'column',
        borderRight: (theme) => `1px solid ${theme.palette.divider}`,
        '& .sidebar-divider': {
          mx: '12px',
          mb: '12px',
        },
      },
      '& .main-content': {
        width: '100%',
        height: '100vh',
        pl: '48px',
        pr: 0,
        py: 0,
        m: 0,
        pt: 0,
        overflow: 'hidden',
        zIndex: 2,
        background: (theme) => theme.palette.background.paper,
      },
    }
  }, [])

  return (
    currentUser && (
      <ZeroProvider zero={z}>
        <ObjectsProvider workspaceId={selectedWorkspace}>
          <SidebarProvider workspaceId={selectedWorkspace}>
            <GlobalStyles styles={globalStyles} />
            <Toaster />
            <CssBaseline />
            <MeetingRecordingsProvider>
              {selectedWorkspace && (
                <SearchModal
                  open={searchOpen}
                  onClose={() => setSearchOpen(false)}
                  allowCreation={true}
                  onSelect={(result) => {
                    setSidebarObject({
                      objectType: result.objectType,
                      objectId: result.objectId,
                      properties: {
                        ...result.properties,
                        workspaceId: selectedWorkspace,
                      },
                      workspaceId: selectedWorkspace,
                    })
                    setSearchOpen(false)
                  }}
                  workspaceId={selectedWorkspace}
                />
              )}
            </MeetingRecordingsProvider>

            <Sidebar
              crmObject={sidebarObject}
              setCrmObject={setSidebarObject}
              onClose={() => {}}
              workspaceId={selectedWorkspace}
            />

            <Box sx={containerSx}>
              <Box
                className="sidebar"
                component="div"
              >
                <WorkspaceDropdown />
                <Divider className="sidebar-divider" />
                <Tooltip
                  title="Click or Command-K to search"
                  arrow={true}
                  placement="right"
                >
                  <IconButton
                    onClick={() => setSearchOpen(true)}
                    sx={{
                      width: '48px',
                      height: '40px',
                      mx: 'auto',
                      opacity: 1,
                      '&:hover': {
                        backgroundColor: (theme) =>
                          theme.palette.secondary.light,
                      },
                      '& .tabler-icon': {
                        color: (theme) => theme.palette.text.primary,
                      },
                    }}
                  >
                    <IconSearch
                      size={18}
                      stroke={2.8}
                    />
                  </IconButton>
                </Tooltip>

                <List sx={{ p: 0 }}>
                  <NavItem
                    label="Today"
                    icon={<IconCalendar />}
                    route={routes.today()}
                    subItems={[
                      {
                        label: 'Your day today',
                        route: routes.today(),
                        icon: <IconCalendar />,
                      },
                    ]}
                  />

                  {ungatedForInbox(currentUser) && (
                    <NavItem
                      icon={<IconInbox />}
                      label="Service & Success"
                      route={routes.customerInbox()}
                      subItems={[
                        {
                          label: 'Your inbox',
                          route: routes.customerInbox(),
                          icon: <IconInbox />,
                        },
                      ]}
                    />
                  )}

                  <NavItem
                    icon={<IconStack2 />}
                    label="Threads & Pages"
                    route={routes.thread({ threadId: 'new' })}
                    subItems={[
                      {
                        label: 'Threads',
                        route: routes.thread({ threadId: 'new' }),
                        icon: <IconStack2 />,
                      },
                      {
                        label: 'Pages',
                        route: routes.pages(),
                        icon: <IconFiles />,
                      },
                      ...(ungatedForTemplates(currentUser)
                        ? [
                            {
                              label: 'Templates',
                              route: routes.templates(),
                              icon: <IconMessageCheck />,
                            },
                          ]
                        : []),
                    ]}
                  />

                  <NavItem
                    icon={<IconChartBar />}
                    label="Sales"
                    route={routes.opportunities()}
                    subItems={[
                      {
                        label: 'Opportunities',
                        route: routes.opportunities(),
                        icon: <IconTrophy />,
                      },
                      {
                        label: 'Reports',
                        route: routes.reportsPipeline(),
                        icon: <IconChartBar />,
                      },
                      ...(ungatedForActivityReporting(currentUser)
                        ? [
                            {
                              label: 'Activity Reporting',
                              route: routes.reportsActivity(),
                              icon: <IconChartScatter />,
                            },
                          ]
                        : []),
                    ]}
                  />

                  {ungatedForMarketing(currentUser) && (
                    <NavItem
                      icon={<IconReportAnalytics />}
                      label="Marketing"
                      route={routes.marketing()}
                      subItems={[
                        {
                          label: 'Segments',
                          route: routes.marketing(),
                          icon: <IconSitemap />,
                        },
                      ]}
                    />
                  )}

                  {ungatedForCustomers(currentUser) && (
                    <NavItem
                      icon={<IconHeartHandshake />}
                      label="Customers"
                      route={routes.customersCurrent()}
                      subItems={[
                        {
                          label: 'Current Customers',
                          route: routes.customersCurrent(),
                          icon: <IconCoins />,
                        },
                      ]}
                    />
                  )}

                  {ungatedForProduct(currentUser) && (
                    <NavItem
                      icon={<IconBuildingStore />}
                      label="Product"
                      route={routes.product()}
                      subItems={[
                        {
                          label: 'Feature Requests',
                          route: routes.product(),
                          icon: <IconLaurelWreath />,
                        },
                      ]}
                    />
                  )}

                  <NavItem
                    icon={<IconServer />}
                    label="Data"
                    route={routes.organizations()}
                    subItems={[
                      {
                        label: 'Organizations',
                        route: routes.organizations(),
                        icon: <IconBuildingSkyscraper />,
                      },
                      {
                        label: 'People',
                        route: routes.people(),
                        icon: <IconUser />,
                      },
                      {
                        label: 'Pipelines',
                        route: routes.pipelines(),
                        icon: <IconColumns2 />,
                      },
                      {
                        label: 'Actions',
                        route: routes.actions(),
                        icon: <IconBolt />,
                      },
                      {
                        label: 'Recordings',
                        route: routes.recordings(),
                        icon: <IconMicrophone />,
                      },
                      {
                        label: 'Instructions',
                        route: routes.instructions(),
                        icon: <IconBubbleText />,
                      },
                      ...(ungatedForDayObjectsPart3(currentUser) &&
                      selectedWorkspace
                        ? [
                            {
                              label: 'Imports',
                              route: routes.import({
                                workspaceId: selectedWorkspace,
                              }),
                              icon: <IconFileImport />,
                            },
                          ]
                        : []),
                    ]}
                  />
                </List>

                {userCoreContact && (
                  <ProfileController userCoreContact={userCoreContact} />
                )}
              </Box>

              <Box className="main-content">
                <UserErrorRemediation />
                <WorkspaceInviteBanner workspaceInvites={workspaceInvites} />
                {children}
              </Box>
            </Box>
          </SidebarProvider>
        </ObjectsProvider>
      </ZeroProvider>
    )
  )
}

const Ga4ContentGroup = {
  today: 'Home',
  prospects: 'Sales',
  opportunities: 'Sales',
  recordings: 'Meetings',
  marketing: 'Marketing',
  customers: 'Customers',
  pages: 'Pages',
  objectDetail: 'Sales',
  people: 'Data',
  pipelines: 'Sales',
  context: 'Data',
  reviewMeeting: 'Meetings',
  pageDetail: 'Pages',
  workspaceMeetingRecording: 'Meetings',
  workspaceLibrary: 'Meetings',
  library: 'Meetings',
  home: 'Home',
  settings: 'Data',
  actions: 'Data',
  organizations: 'Data',
  reportsPipeline: 'Sales',
  customerInbox: 'Customers',
  customersCurrent: 'Customers',
  product: 'Product',
  marketingContent: 'Marketing',
  marketingCampaigns: 'Marketing',
}

const ModernLayout = ({ children }) => {
  const location = useLocation()
  const route = useRouteName()
  const { currentUser } = useAuth()

  // Set user ID when the user changes
  useEffect(() => {
    if (currentUser?.id) {
      // Set user ID for GA4
      ReactGA.set({ userId: currentUser.id })
    } else {
      // Clear user ID if not available
      ReactGA.set({ userId: undefined })
    }
  }, [currentUser?.id])

  // Track pageviews separately
  useEffect(() => {
    const path = location?.pathname

    // Use route name as a more concise page title
    // This avoids long dynamic paths with IDs
    const pageTitle = route || 'Unknown Page'

    const payload = {
      hitType: 'pageview' as const,
      page: path,
      title: pageTitle, // Using the route name as the title for conciseness
      contentGroup1: Ga4ContentGroup[route] || 'Other',
    }

    logger.dev({ GA4Pageview: payload })

    if (path) {
      try {
        ReactGA.send(payload)
      } catch (e) {
        logger.error('GA tracking error', e as Error, {
          location: path,
        })
      }
    }
  }, [location?.pathname, route])

  return (
    currentUser && (
      <DayProvider>
        <AvatarCacheProvider>
          <ObjectCacheProvider>
            <ModernLayoutInner>{children}</ModernLayoutInner>
          </ObjectCacheProvider>
        </AvatarCacheProvider>
      </DayProvider>
    )
  )
}

export default ModernLayout
